@import '~antd/dist/antd.css';

tr.info-row {
  background-color: #e6f7ff;
  color: #000000;
}

tr.success-row {
  background-color: #f6ffed;
  color: #000000;
}

tr.warning-row {
  background-color: #fffbe6;
  color: #000000;
}

tr.error-row {
  background-color: #fff1f0;
  color: #000000;
}
